/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CustomerService } from '@/components/content/CustomerService';
import { Notifications } from '@/components/content/Notifications';
import { SessionErrorDialog } from '@/components/content/SessionErrorDialog';
import { layoutManifest } from '@/components/layouts/manifest';
import { Meta } from '@/data/Meta';
import { useStaticSettings } from '@/data/Settings';
import { SettingProvider } from '@/data/context/setting';
import { Layout } from '@/data/types/Layout';
import { ThemeSettingsProvider } from '@/styles/theme';
import { CssBaseline, SxProps, Theme, ThemeProvider } from '@mui/material';
import Script from 'next/script';
import Head from 'next/head';
import { FC } from 'react';
import { ContentProvider } from '@/data/context/content';
import { usePageDataFromId } from '@/data/_PageDataFromId';
import { Canonical } from '@/components/blocks/Canonical';
import { useBreadcrumbTrail } from '@/data/Content/BreadcrumbTrail';
import { ProcessedContent } from '@/data/types/Marketing';
import { ESpotContainerType } from '@/data/Content/_ESpotDataFromName';

type PageProps = {
	meta: Meta;
	layout: Layout;
	theme: Theme;
	additives?: { [key: string]: SxProps | ((theme: Theme) => SxProps) };
	user_membership?: any;
	plpEspotData?: ProcessedContent[] | undefined;
	triggerPLPRibbonEspotCall?: (extraArgument?: null, options?: { optimisticData?: { success: boolean; message: string } } | undefined)
	=> Promise<ESpotContainerType | undefined> | undefined;
};

export const hasNoIndex = (tokenExternalValue: string | undefined): boolean => {
	if (tokenExternalValue && tokenExternalValue.search('_noindex') !== -1) return true;

	return false;
};

export const PageBlock: FC<PageProps> = ({ meta, layout, theme, additives, user_membership, plpEspotData: plpRibbonEspotData, triggerPLPRibbonEspotCall }) => {
	const Layout = layout && layoutManifest[layout.name] ? layoutManifest[layout.name] : () => null;
	const { data, loading } = usePageDataFromId();
	const { breadcrumb: breadcrumbData } = useBreadcrumbTrail();
	return (
		<>
			<Head>
				<title>{meta?.title}</title>
				<meta name="description" content={meta?.description} />
				<meta name="keywords" content={meta?.keywords} />
				<link rel="icon" href="/favicon.png" />

			</Head>
			<Script src="https://kit.fontawesome.com/1803b2ceec.js" />
			{data && !loading ?
				<Canonical pageData={data} /> : null
			}

			{hasNoIndex(data?.tokenExternalValue) || (breadcrumbData?.length === 0 && data?.tokenName === 'CategoryToken') ? (
				<Head>
					<meta name="robots" content="NOINDEX,NOFOLLOW" />
				</Head>

			) : null}


			<CustomerService />
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ThemeSettingsProvider value={{ additives }}>
					<ContentProvider value={{ user_membership, plpRibbonEspotData, triggerPLPRibbonEspotCall }}>
						<Layout layout={layout} />
					</ContentProvider>
					<Notifications />
					<SessionErrorDialog />
				</ThemeSettingsProvider>
			</ThemeProvider >
		</>
	);
};

export const StaticPageBlock: FC<PageProps> = ({ meta, layout, theme, additives }) => {
	const { settings } = useStaticSettings();

	return settings ? (
		<SettingProvider value={settings}>
			<PageBlock meta={meta} layout={layout} theme={theme} additives={additives} />
		</SettingProvider>
	) : null;
};
